import { bannerTypes } from '@@/components/Common/Banner.vue';
import { validateReturnTo } from '@@/utils/CommonUtils';
import { useMetaStore } from '@@/stores/Meta';

export const addReturnTo = (relativeUrl, query) => {
  if (!query?.return_to) {
    return relativeUrl;
  }

  const [path, queryString = ''] = String(relativeUrl).split('?');
  const searchParams = new URLSearchParams(queryString);
  searchParams.set('return_to', query.return_to);

  return `${path}?${searchParams.toString()}`;
};

export const navigateReturnTo = ($router, returnTo) => {
  returnTo = validateReturnTo(returnTo);

  const isNuxtRoute = $router.resolve(returnTo).matched.length;
  navigateTo(returnTo, { external: !isNuxtRoute });
};

export const getLoginError = (loginStore) => {
  const { loginError } = loginStore;
  loginStore.setLoginError(null);
  return loginError;
};

export const getPromoCodeBannerType = (promoCode) => (promoCode?.is_active === false
  ? bannerTypes.error
  : bannerTypes.membership);

export const getPostRegReturnToUrl = (returnToFromQuerystring) => {
  const metaStore = useMetaStore();

  if (!returnToFromQuerystring) {
    return (metaStore.getDefaultPostRegView === 'my-location')
      ? '/my-location'
      : '/user/favorites';
  }

  return returnToFromQuerystring;
};

export const getStartFreeTrialUrl = (params = {}) => {
  const {
    isGuest = true,
    query = {},
  } = params;

  const url = isGuest ? '/user/register' : '/buy';
  const search = new URLSearchParams(query).toString();

  return search ? `${url}?${search}` : url;
};
